import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "questions"
    }}>{`Questions`}</h2>
    <p>{`The Carbon core team provides support for users of the design system. Find the use case below that most closely matches your own for the quickest response.`}</p>
    <h3 {...{
      "id": "carbon-website"
    }}>{`Carbon website`}</h3>
    <p>{`Please take some time to explore the content on this website before engaging the Carbon team. The site is very comprehensive and most of the guidelines and components are well documented.`}</p>
    <h3 {...{
      "id": "frequently-asked-questions"
    }}>{`Frequently asked questions`}</h3>
    <p>{`Answers to the most common questions about the design system can be found in the `}<a parentName="p" {...{
        "href": "faq"
      }}>{`Carbon FAQ`}</a>{`.`}</p>
    <h3 {...{
      "id": "slack-channels"
    }}>{`Slack channels`}</h3>
    <p><em parentName="p">{`Internal IBM users only.`}</em>{` The Carbon core team maintains the following channels and will provide support as time permits.`}</p>
    <p><strong parentName="p">{`Please try searching the Slack channel for your topic first.`}</strong>{` Slack’s filtering feature will return more targeted and relevant results. For instance, if you have a technical question about the `}<inlineCode parentName="p">{`DataTable`}</inlineCode>{` component, you could search “DataTable” in Slack and then filter by the #carbon-components channel. `}<em parentName="p">{`Tip: You can start a new search directly from the message box using the `}<inlineCode parentName="em">{`/s`}</inlineCode>{` slash command.`}</em></p>
    <p>{`For designer questions: `}<a parentName="p" {...{
        "href": "https://ibm-studios.slack.com/messages/C0M053VPT/"
      }}>{`#carbon-design-system`}</a></p>
    <p>{`For developer questions: `}<a parentName="p" {...{
        "href": "https://ibm-studios.slack.com/messages/C046Y0YUD/"
      }}>{`#carbon-components`}</a>{`, `}<a parentName="p" {...{
        "href": "https://ibm-studios.slack.com/messages/C2K6RFJ1G/"
      }}>{`#carbon-react`}</a></p>
    <h3 {...{
      "id": "email"
    }}>{`Email`}</h3>
    <p>{`Messages to `}<a parentName="p" {...{
        "href": "mailto:carbon@us.ibm.com"
      }}>{`carbon@us.ibm.com`}</a>{` will be addressed by the Carbon technical team as quickly as possible.`}</p>
    <h3 {...{
      "id": "twitter"
    }}>{`Twitter`}</h3>
    <p>{`The Carbon team can also be reached on Twitter: `}<a parentName="p" {...{
        "href": "https://twitter.com/_carbondesign?lang=en"
      }}>{`@`}{`_`}{`carbondesign`}</a></p>
    <h2 {...{
      "id": "suggestions-or-contributions"
    }}>{`Suggestions or contributions`}</h2>
    <h3 {...{
      "id": "github-pull-requests"
    }}>{`GitHub pull requests`}</h3>
    <p>{`If you have a specific fix or contribution, you can generate a pull request in the appropriate Carbon repo.`}</p>
    <h3 {...{
      "id": "github-issues"
    }}>{`GitHub issues`}</h3>
    <p>{`Bug reports, feature requests, and general feedback can be delivered to the Carbon team via the creation of a GitHub issue.`}</p>
    <h4 {...{
      "id": "design-issues"
    }}>{`Design issues`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon-design-kit/issues/new"
        }}>{`Design Kit`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon-icons/issues/new"
        }}>{`Icons`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon-website/issues/new/choose"
        }}>{`Website`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon/issues/new/choose"
        }}>{`Components`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon/issues/new/choose"
        }}>{`Elements`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon-charts/issues/new"
        }}>{`Charts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/issue-tracking/issues/new"
        }}>{`Everything else`}</a></li>
    </ul>
    <h4 {...{
      "id": "code-issues"
    }}>{`Code issues`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon/issues/new/choose"
        }}>{`carbon-components`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon/tree/master/packages/react/issues/new/choose"
        }}>{`carbon-components-react`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon-components-angular/issues/new"
        }}>{`carbon-components-angular`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon-components-vue/issues/new"
        }}>{`carbon-components-vue`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon-charts/issues/new"
        }}>{`carbon-charts`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      